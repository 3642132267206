const PENDING = 'pending';
const SENDTO = 'sendTo';

const session = () => window.sessionStorage;

const getter = (key: string) => session().getItem(key);
const setter = (key: string, value: string) => session().setItem(key, value);
const remove = (key: string) => session().removeItem(key);

export const getPending = () => getter(PENDING);
export const removePending = () => remove(PENDING);
export const setPending = (boolean = 'true') => setter(PENDING, boolean);

export const getSendTo = () => getter(SENDTO);
export const removeSendTo = () => remove(SENDTO);
export const setSendTo = (value: string) => setter(SENDTO, value);
