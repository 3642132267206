import * as React from 'react';
import { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { icons } from '@invertase/ui';

import Page from '../components/Page';
import Container from '../components/Container';

import { getUser } from '../hooks/useUser';

import {
  getPending,
  getSendTo,
  removePending,
  removeSendTo,
  setPending,
  setSendTo,
} from '../utils/window';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Link from '../components/Link';
import cx from 'classnames';
import useFirebase from '../hooks/useFirebase';
import { GatsbyLocation } from '../types/location';

const { GitHub } = icons;

function LoginPage({ location }: { location: GatsbyLocation }) {
  const firebase = useFirebase();
  const [loading, user] = getUser();

  // Whether or not the user has a pending redirect to handle
  const [isPending, setIsPending] = useState<boolean>(false);

  if (location?.state?.sendTo) {
    setSendTo(location.state.sendTo);
  }

  function redirect() {
    const sendTo = getSendTo();

    if (sendTo) {
      removeSendTo();
      navigate(sendTo);
      return null;
    }

    navigate('/account');
    return null;
  }

  function login() {
    if (!firebase) return;

    // set a pending flag on session
    setPending();

    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const instance = require('firebase');
    const provider = new instance.auth.GithubAuthProvider();
    firebase.auth().signInWithRedirect(provider).catch(console.error);
  }

  useEffect(() => {
    if (!firebase) return;

    if (getPending()) {
      setIsPending(true);
    }

    firebase
      .auth()
      .getRedirectResult()
      .then(() => removePending())
      .catch(console.error);
  }, [firebase]);

  // If Firebase user has loaded & logged in
  if (!loading && user) {
    redirect();
    return null;
  }

  return (
    <Page location={location} title="Login" noindex header={<Header search={false} />}>
      <Container>
        <div className="max-w-lg mx-auto mt-24 dark:text-white text-center py-6 px-12 rounded-lg border">
          <div className="text-center mb-8">
            <img src="/logo-icon.png" alt="Logo" className="w-16 mx-auto" />
            <h1 className="text-3xl font-thin">Notifee</h1>
          </div>

          <p className="text-red-800 bg-gray-300 font-bold rounded p-2">
            You only need to login to Notifee if you have previously purchased a license before
            Notifee moved to being a free product. These account sections will be deprecated and
            removed in future.
          </p>
          <button
            className={cx(
              'my-8 flex items-center justify-center w-full px-5 py-2 my-4 text-white dark:text-black rounded bg-black dark:bg-white hover:bg-gray-900 dark-hover:bg-gray-100',
              {
                'opacity-50 cursor-not-allowed': loading,
              },
            )}
            type="button"
            onClick={() => !loading && login()}
          >
            <GitHub size={26} className="text-white dark:text-black mr-4" />
            {isPending ? 'Logging you in...' : 'Login with GitHub'}
          </button>
          <p className="text-xs mt-8">
            We do not share any email addresses or use them for marketing purposes. Email addresses
            are used for transactional emails only e.g. purchase or invoice emails.
            <br />
            <br />
            <Link to="/privacy-policy">See our privacy policy for more information.</Link>
          </p>
        </div>
      </Container>
      <Footer />
    </Page>
  );
}

export default LoginPage;
